<template>
  <PopperPro element-type="span">
    <Icon name="help" class="cursor-pointer mx-2"></Icon>
    <PopupMenu style="max-width: 30rem" class="px-4 py-2" v-html="enrich(props.content, {skipUrls: true})"></PopupMenu>
  </PopperPro>
</template>

<script>
import PopperPro from "./PopperPro";
import PopupMenu from "./popup/PopupMenu";
import {enrich} from "../helpers/rich_text_helper";

export default {
  props: {
    content: String,
  },
  components: {
    PopperPro,
    PopupMenu,
  },
  setup(props) {
    return {props, enrich}
  }
}
</script>

<style>


</style>
