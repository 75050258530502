import {nextId} from './id_helper'
import IndexArray from 'index-array'
import _ from 'lodash'

window.IndexArray = IndexArray


function decorateArrays(obj, nested = true) {
  let result = obj

  if (_isObject(obj)) {
    for (let key in obj) {
      obj[key] = decorateArrays(obj[key])
    }
  }

  if (Array.isArray(obj)) {
    for (let i=0; i<obj.length; i++) {
      obj[i] = decorateArrays(obj[i])
    }
  }


  if (Array.isArray(obj) && !(obj instanceof IndexArray)) {
    result = new IndexArray(...obj)
  }

  return result
}


function decorateUids(obj, nested = true) {

  if (Array.isArray(obj)) {
    obj.forEach((item) => {
      if (_isObject(item) && !item.uid) {
        item.uid = nextId()
      }
    })
  }

  if (obj instanceof Object && nested) {
    for (let key in obj) {
      decorateUids(obj[key], nested)
    }
  }

  return obj
}


function decorateFull(obj, nested = true) {
  return decorateArrays(decorateUids(obj, nested), nested)
}


function _isObject(obj) {
  return obj instanceof Object && !Array.isArray(obj)
}

function arrayToObject(arr) {
  let result = {}
  arr.forEach((item) => {
    result[item.key] = item.value
  })
  return result
}

function objectToArray(obj) {
  return _.map(obj, (value, key) => {
    return {key, value}
  })
}

export {decorateArrays, decorateUids, decorateFull, arrayToObject, objectToArray}