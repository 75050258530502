<template>
    <div v-if="props.richText"
        v-show="!state.active"
        ref="div"
        style="min-height: 1.5rem"
        @click="startEdit($event)">
      <span v-html="enrich(props.modelValue)" />
    </div>
    <div v-if="!props.richText"
        v-show="!state.active"
        style="min-height: 1.5rem"
        ref="div"
        :class="{'text-gray-300': !props.modelValue}"
        @click="startEdit()">
      {{props.modelValue || props.placeholder}}
    </div>
    <textarea v-show="state.active"
              @keydown.ctrl.enter="endEdit()"
              @keydown="onKeyDown"
              @change="onChange"
              ref="textArea"
              :style="{minHeight: '10px', height: state.contentHeight}"
              class="edit-area bg-green-100 p-0 m-0 border-none "
              v-model="state.text">
    </textarea>
</template>

<script>
import {reactive, ref, nextTick} from 'vue'
import {enrich} from "../helpers/rich_text_helper";

export default {
  props: {
    modelValue: String,
    richText: Boolean,
    placeholder: {type: String, default: ''},
    singleLine: {type: Boolean, default: false}
  },
  emits: ['update:modelValue', 'endEdit'],
  setup: function (props, context) {

    let state = reactive({
      active: false,
      text: '',
      contentHeight: '24px',
      close: null,
    })

    let textArea = ref()
    let div = ref()

    function onChange(e) {
      context.emit('update:modelValue', state.text)
    }

    function onKeyDown(e) {
      if (e.key === 'Enter' && props.singleLine) {
        e.preventDefault()
        return endEdit()
      }
      updateHeight()
    }

    function updateHeight(value) {
      if (props.singleLine) return
      setTimeout(() => {
        if (value) {
          state.contentHeight = value+'px'
        } else {
          state.contentHeight = (textArea.value?.scrollHeight || 24) + 'px'
        }
      })
    }


    function startEdit(e) {
      if (e?.target?.nodeName === 'A') {
        return
      }

      state.text = props.modelValue

      let input = textArea.value
      setTimeout(() => {
        input.focus()

        state.close = function(e) {
          if (e.target !== input) {
            endEdit()
          }
        }
        window.addEventListener('click', state.close)
        window.addEventListener('blur', state.close)
      }, 50)
      nextTick(() => {
        updateHeight(div.value.clientHeight)
        state.active = true
      })
    }

    function endEdit() {
      state.active = false
      window.removeEventListener('click', state.close)
      window.removeEventListener('blur', state.close)
      context.emit('endEdit')
    }

    return {state, props, startEdit, endEdit, enrich, div, textArea, onKeyDown, onChange}
  }
}
</script>

<style>
.edit-area {
  resize: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.edit-area::-webkit-scrollbar {
  display: none;
}

</style>
