<template>
  <div class="flex">

    <div style="word-break: break-word; margin-right: -2rem"  class="bg-white shadow p-2 my-1 rounded flex flex-row flex-grow">
      <!-- overlay is done with https://stackoverflow.com/a/48877138 -->
      <div style="flex: none; width: 100%;">

        <CardTagList :tags="card.tags"></CardTagList>
        <ClickToEdit :rich-text="true" @endEdit="onCardEdit(card)" v-model="card.content" />
      </div>
      <div v-if="card.changeKey && new Date(card.changedAt) > new Date(card.lastSeenAt)"
           style=""
           class="new-overlay" @click="seen(card)">
      </div>
    </div>

    <div class="flex flex-col">
      <PopperPro>
        <button class=" icon mx-1 my-2">
          <Icon name="edit"></Icon>
        </button>

        <PopupMenu>
          <PopperPro>
            <PopupItem>Edit tags</PopupItem>
            <TagSelection @select="onTagChange()" :taggable="card"/>
          </PopperPro>
          <PopupItem class="text-red-600" @click="deleteCard(card)">Delete</PopupItem>
        </PopupMenu>
      </PopperPro>
    </div>

  </div>
</template>

<script>
import {reactive} from 'vue'
import ClickToEdit from "./ClickToEdit";
import TagSelection from './TagSelection'
import boardService from '../services/board_service'
import PopupItem from "./popup/PopupItem";
import PopupMenu from "./popup/PopupMenu";
import PopperPro from "./PopperPro";
import CardTagList from "./CardTagList";

export default {
  components: {
    CardTagList,
    PopperPro,
    PopupMenu,
    PopupItem,
    ClickToEdit,
    TagSelection,
  },
  props: {
    card: Object,
    panel: Object
  },
  emits: ['update:modelValue', 'endEdit'],
  setup: function (props) {

    let state = reactive({
      editActive: false,
      editPopper: null,
      editElements: []
    })


    function onCardEdit(card) {
      boardService.updateCard(card)
    }

    function deleteCard(card) {
      boardService.deleteCard(card)
    }

    function seen(card) {
      card.lastSeenAt = new Date().toISOString()
      boardService.updateCard(card)
    }

    function onTagChange() {
      boardService.updateCard(props.card)
    }


    return {state, card: props.card, panel: props.panel, onCardEdit, deleteCard, seen, onTagChange}
  }
}
</script>

<style scoped>
.new-overlay {
  width: calc(100% + 1rem);
  height: calc(100% + 1rem);
  background-color: rgba(255, 180, 0, 0.15);
  flex: none;
  margin-left: calc(-100% - 0.5rem);
  margin-top: -0.5rem;
}

</style>
