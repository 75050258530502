<template>
  <div class="loading-spinner"></div>
</template>

<script>

export default {}

</script>

<style scoped>

.loading-spinner {
  border: 3px solid rgba(255,255,255,0);
  border-radius: 50%;
  border-top: 3px solid white;
  width: 18px;
  height: 18px;
  -webkit-animation: spin 1.2s linear infinite; /* Safari */
  animation: spin 1.2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>
