import {reactive} from 'vue'
import http from "./http_service";
import {fastHash} from '../helpers/encryption_helper'

let state = reactive({
  loading: false,
  loginComplete: false,
  currentUser: null,
})

function logIn(email, password) {
  return http.post('/users/login', {user: {email,  password: fastHash(password)}})
  .then((response) => {
    setUser(response.data)
  })
  .catch(() => {
    setUser()
  })
}

function logOut() {
  return http.get('/users/logout')
  .then(() => {
    state.currentUser = null
  })
}

function register(email, password) {
  return http.post('/users', {user: {email, password: fastHash(password)}})
  .then((response) => {
    state.currentUser = response.data
  })
}

function setUser(user = {}) {
  state.loading = false
  state.loginComplete = true

  if (user?.id) {
    state.currentUser = user
  }
}

function checkLogin() {
  if (!state.loading && !state.loginComplete) {
    state.loading = true
    state.loginComplete = false
    http.get('/api/user')
    .then((response) => {
      setUser(response.data)
    })
    .catch(() => {
      setUser()
    })
  }
}


function useLogin(immediate = true) {

  if (immediate) {
    checkLogin()
  }

  return {logIn, logOut, register, checkLogin, loginState: state}
}


export default useLogin

