import http from './http_service'
import {reactive} from 'vue'
import {nextId} from '../helpers/id_helper'
import _ from 'lodash'
import {subscribe} from './subscription_service'

let service = BoardService()


function BoardService() {
  let self = {
    _subscription: null,
    unsubscribe,
    board: reactive({}),
    load,
    updateBoard,
    addPanel,
    updatePanel,
    deletePanel,
    addCard,
    updateCard,
    deleteCard,
    createTag,
    updateTag,
    deleteTag,
    syncSource,
    saveSource,
    deleteSource,
  }

  function load(id) {
    let result = http.get('/api/boards').then((response) => {
      Object.assign(self.board, response.data)
      window.board = self.board
    }).then(() => {
      self._subscription = subscribe('/api/boards', {delay: 5000}).onSuccess((response) => {
        Object.assign(self.board, response.data)
        window.board = self.board
      })
    })

    return result
  }

  function unsubscribe() {
    self._subscription?.stop()
  }

  function updateBoard(board) {
    let boardPayload = _.cloneDeep(board)
    boardPayload.panels = boardPayload.panels.map(({id}) => {
      return {id}
    })
    delete boardPayload.sources
    return http.put(`/api/boards/${boardPayload.id}`, boardPayload)
    .then((response) => {
      Object.assign(self.board, response.data)
      window.board = self.board
    })
  }



  function addPanel(panel) {
    panel.boardId = self.board.id
    self.board.panels.push(panel)
    panel = self.board.panels[self.board.panels.length - 1]
    return updatePanel(panel)
    .then((response) => {
      Object.assign(panel, response.data)
    })
  }

  function updatePanel(panel) {
    return http.put('/api/panels', panel)
      .then((response) => {
        Object.assign(panel.cards, response.data.cards)
        return response
      })
  }

  function deletePanel(panel) {
    return http.delete(`/api/panels/${panel.id}`, panel)
    .then(() => {
      self.board.panels.remove({id: panel.id})
    })
  }



  function addCard(card) {
    let panel = self.board.panels.fetch({id: card.panelId})
    panel.cards.push(card)
    card = panel.cards[panel.cards.length-1]
    return updateCard(card)
  }

  function updateCard(card) {
    return http.put('/api/cards', card)
    .then((response) => {
      Object.assign(card, response.data)
    })
  }

  function deleteCard(card) {
    let panel = self.board.panels.fetch({id: card.panelId})
    panel.cards.remove({id: card.id})
    http.delete(`/api/cards/${card.id}`)
  }



  function createTag(newTag) {
    Object.assign(newTag, {boardId: self.board.id})
    self.board.tags.push(newTag)
    newTag = self.board.tags[self.board.tags.length-1]
    return http.post('/api/tags', newTag)
    .then((response) => {
      Object.assign(newTag, response.data)
    })
  }

  function updateTag(tag) {
    return http.put(`/api/tags/${tag.id}`, tag)
    .then((response) => {
      Object.assign(tag, response.data)
    })
  }

  function deleteTag(tag) {
    return http.delete(`/api/tags/${tag.id}`)
    .then((response) => {
      self.board.tags.remove({id: tag.id})
    })
  }



  function syncSource(src = null) {
    let sources = !!src ? [src] : self.board.sources

    let promises = []
    sources.forEach((source) => {
      let panel = board.panels.fetch({id: source.panelId})
      if (!panel) {
        console.error(`could not find panel for id '${source.panelId}'`)
        return
      }

      promises.push(http.get(source.url, {params: source.urlParams || {}, headers: source.headers || {}})
      .then((response) => {
        let newCards = response.data.map((item) => {
          return {
            panelId: source.panelId,
            refId: interpolate(source.cardMapping.refId, item),
            content: interpolate(source.cardMapping.content, item),
            changeKey: interpolate(source.cardMapping.changeKey, item, true),
            changedAt: new Date().toISOString(),
            tags: source.tags.clone(),
            uid: nextId()
          }
        })

        for (let iCard=0; iCard < newCards.length; iCard++) {
          let card = newCards[iCard]
          if (!card) continue

          let foundCard = null;
          for (let iPanel=0; iPanel < self.board.panels.length; iPanel++) {
            let panel = self.board.panels[iPanel]
            if (!panel.cards.fetch) {
              debugger
            }
            foundCard = panel.cards.fetch({refId: card.refId})
            if (foundCard) break
          }

          if (foundCard) {
            card.id = foundCard.id
            if (foundCard.changeKey !== card.changeKey) {
              foundCard.changeKey = card.changeKey
              self.updateCard(foundCard)
            }
            continue
          }
          let ord = _.defaultTo(panel.cards.at(-1)?.ord, -1) + 1
          card.ord = ord
          self.addCard(card)
        }

      })
      .then(() => {
        // TODO: update source's last updated at
        // boardService.updateSource({id: source.id, lastUpdatedAt: now.toISOString()})
      }))

    })

    return Promise.all(promises)
  }

  function saveSource(source) {
    return http.put('/api/board_sources', source)
    .then((response) => {

      let result = Object.assign(source, response.data)
      let index = self.board.sources.fetchIndex({id: source.id})
      if (index >= 0) {
        self.board.sources.replace({id: source.id}, source)
      } else {
        self.board.sources.push(source)
      }
      return result
    })
  }

  function deleteSource(source) {
    return http.delete(`/api/board_sources/${source.id}`)
    .then(() => {
      self.board.sources.remove({id: source.id})
    })
  }

  function interpolate(pattern, item, optional = false) {
    if (!pattern && optional) {
      return null
    }
    return pattern.replace(/\$([a-zA-Z0-9_.]+)/g, (match, key) => {
      // TODO: Throw error if key is missing. The user probably wants to know.
      return item[key]
    })
  }


  return self
}

export default service