import {urlRegex} from "./regexes";
import _ from 'lodash'

function enrich(text, options = {}) {
  let richText = sanitize(text)

  if (!options.skipUrls) {
    richText = richText.replace(urlRegex, (match, first) => {
      let url = /^(http:\/\/|https:\/\/)/.test(match) ? match : `https://${match}`
      return `<a href="${url}" target="_blank">${match}</a>`
    })
  }

  richText = richText.replaceAll('\n', '<br>')
  if (richText.endsWith('<br>')) richText += '<br>'
  return richText
}

function sanitize(text) {
  return _.escape(text)
}

export {enrich}