<template>
  <div>
    <label class="font-bold">{{props.name}}</label>
    <HelpPopup v-if="props.help" :content="props.help"/>
  </div>
</template>

<script>
import HelpPopup from "../HelpPopup";

export default {
  components: {
    HelpPopup,
  },
  props: {
    name: String,
    help: String,
  },
  setup: function (props) {
    return {props}
  }
}

</script>

<style>

</style>
