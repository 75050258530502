<template>
  <button>
    <slot></slot>
  </button>
</template>

<script>
export default {}
</script>

<style>

button {
  background-color: var(--primary-color);
}

button:hover,
button:active,
button:not(:disabled):active {
  background-color: var(--primary-dark-color);
}

a {
  color: var(--primary-color);
}

a:hover,
a:active {
  color: var(--primary-dark-color);
  text-decoration: none;
}

button.icon {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  background-color: rgb(243, 244, 246); /* gray-100 */
  border: none;
  color: #555;
}

button.icon:hover {
  background-color: rgba(229, 231, 235); /* gray-200 */
}

button.small {
  padding: 4px 8px;
}

button.link {
  background-color: transparent;
  border: none;
  color: var(--primary-color);
}

button.link:hover,
button.link:active {
  background-color: rgba(255, 255, 255, 0.4);
}


button.outline-primary {
  border-color: var(--primary-color);
  background-color: white;
  color: var(--primary-color);
}

button.outline-primary:hover {
  border-color: var(--primary-dark-color);
  background-color: rgb(229, 231, 235);
  color: var(--primary-dark-color);
}

button.outline-danger {
  border-color: rgb(220, 38, 38); /* red-600 */
  background-color: white;
  color: rgb(220, 38, 38);
}

button.outline-danger:hover {
  border-color: rgb(220, 38, 38);
  background-color: rgb(229, 231, 235); /* gray-200 */
  color: rgb(220, 38, 38);
}

button.clear,
button.clear:focus {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}

</style>
