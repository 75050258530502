<template>
  <div class="panel rounded mx-2 flex flex-col" style="max-height: 100%; min-height: 0">
    <div class="flex flex-col" style="max-height: 100%; min-height: 0">
      <div class="flex flex-row items-center mx-2">
        <ClickToEdit @endEdit="onPanelEdit(props.panel)" v-model="props.panel.name" single-line/>
        <div class="flex-grow"></div>

        <PopperPro>
          <Button class="icon mx-1 my-0">
            <Icon name="edit"></Icon>
          </Button>
          <PopupMenu>
            <Confirmable @click="emptyPanel(props.panel)">
              <PopupItem>Empty List</PopupItem>
            </Confirmable>
            <Confirmable @click="deletePanel(props.panel)">
              <PopupItem>Delete List</PopupItem>
            </Confirmable>
          </PopupMenu>
        </PopperPro>
      </div>


      <IndexDraggable
          class="draggable mini-scrollbar"
          :group="{name: 'cards'}" item-key="uid"
          :delay="state.draggableDelay"
          v-model="props.panel.cards"
          @start="startDrag($event)"
          @end="endDrag($event)"
          @change="onDragCard($event, props.panel)">
        <template #item="{element: card}">
          <Card :panel="props.panel" :card="card" />
        </template>
      </IndexDraggable>

      <div class="filler w-full" :class="{'click-through': globalState.dragging}">
        <Button
            class="new-card self-center m-2 link block margin-auto"
            @click="createCard()">
          <Icon name="addCircle" />  New Card
        </Button>
      </div>

    </div>

  </div>
</template>

<script>
import {reactive} from 'vue'
import boardService from '../services/board_service'
import {nextId} from "../helpers/id_helper";
import IndexArray from "index-array";
import {globalState} from "../services/gui_service"

import Card from './Card'
import ClickToEdit from './ClickToEdit'
import Confirmable from "./popup/Confirmable";
import IndexDraggable from "./IndexDraggable";
import PopperPro from "./PopperPro";
import PopupItem from "./popup/PopupItem";
import PopupMenu from "./popup/PopupMenu";


export default {
  props: {panel: Object},
  components: {
    Card,
    ClickToEdit,
    Confirmable,
    IndexDraggable,
    PopperPro,
    PopupMenu,
    PopupItem,
  },
  setup: function (props) {


    let state = reactive({
      draggableDelay: 0, //TODO: Make this a function of whether a mobile device is being used and make it global
    })


    function createCard() {
      boardService.addCard({uid: nextId(), panelId: props.panel.id, content: "", tags: new IndexArray()})
    }

    function onDragCard(event, panel) {
      if (event.added || event.moved) {
        boardService.updatePanel(panel)
      }
    }

    function onPanelEdit(panel) {
      boardService.updatePanel(panel)
    }

    function sync() {
      state.syncLoading = true
      boardService.syncSource()
      .finally(() => {
        state.syncLoading = false
      })
    }

    function deletePanel(panel) {
      boardService.deletePanel(panel)
    }

    function emptyPanel(panel) {
      for (let iCard = panel.cards.length-1; iCard >= 0; iCard--) {
        boardService.deleteCard(panel.cards[iCard])
      }
    }

    function startDrag(event) {
      globalState.dragging = true
    }

    function endDrag(event) {
      globalState.dragging = false
    }

    return {state, createCard, props, globalState,
      deletePanel, emptyPanel, onDragCard, onPanelEdit, sync, startDrag, endDrag}
  }
}
</script>

<style scoped>

.draggable {
  padding-bottom: 30vh;
  margin-bottom: -30vh;
  margin-left: 0.25rem;
  padding-left: 0.25rem;
  margin-right: 0.25rem;
  padding-right: 0.25rem;
  overflow-y: auto;
}

.panel {
  background-color: rgba(229, 231, 235); /* bg-gray-200 */
  padding: 0.5rem 0 0; /* match-A */
  width: 20rem;
}
.filler {
  background-color: rgb(229, 231, 235); /* bg-gray-200 */
  z-index: 1;
  border-radius: 0.25rem; /* rounded */
}
.click-through {
  pointer-events: none;
}

button.new-card {

  width: 10rem;
  color: #777;
  font-weight: bold;
}

.mini-scrollbar::-webkit-scrollbar {
  width: 8px;
}
.mini-scrollbar::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: rgba(0,0,0, 0.10);
  margin-bottom: 30vh;
  margin-top: 2px;
}
.mini-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: rgba(0,0,0, 0.10);
}

</style>
