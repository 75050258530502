<template>
  <Header />
  <div class="blog" style="background-color: rgba(255, 255, 255, 0.8)">
    <div class="content bg-white" style="height: 100%">
      <div class="text-lg" v-html="state.content"></div>
    </div>
  </div>
  <Footer />

</template>

<script>
import {reactive, watch, computed} from 'vue'
import {useRouter} from 'vue-router'
import http from "./services/http_service";
import Header from './components/Header'
import Footer from './components/Footer'


export default {
  components: {
    Header,
    Footer,
  },
  props: {

  },
  setup: function (props) {
    let router = useRouter()
    let state = reactive({
      content: '',
      slug: computed(() => {return router.currentRoute.value.params.slug || ''}),
    })
    window.router = router
    window.state = state

    watch(() => state.slug, () => {
      if (!state.slug) {
        return router.push('/')
      }
      state.content = ''
      http.get(`/api/blogs/${state.slug}`)
      .then((response) => {
        state.content = response.data.content
      })
      .catch((error) => {
        console.log({error})
      })

    }, {immediate: true})


    return {props, state}
  }
}
</script>

<style>

.blog {
  background-color: rgb(255,255,255, 0.8);
}

.blog h2 {
  font-size: 1.5rem;
}

.blog li {
  list-style-type: disc;
}

.blog .content {
  min-height: calc(100vh - 4rem - 20rem/2);
  max-width: 100%;
  width: 60rem;
  margin: auto;
  padding: 1rem 2rem;
}

.blog .date {
  opacity: 0.5;
  font-size: 1rem;
}

</style>
