<template>
  <div style="height: 100vh;"  class="flex flex-col justify-center">

    <div style="max-width: 30em; width: 100%" class="login-box bg-white shadow self-center p-4 rounded">
      <h3 class="mt-0">Email Confirmation</h3>
      <h5 v-if="state.loading">Confirming...</h5>
      <h5 v-if="state.confirmed">Confirmed!</h5>
      <h5 v-if="!state.loading && !state.confirmed">Unable to confirm email.</h5>
    </div>

  </div>

</template>

<script>
import {reactive, onMounted} from 'vue'
import {useRoute} from "vue-router";
import http from './services/http_service'

export default {
  setup() {
    let state = reactive({
      loading: true,
      confirmed: false,
    })
    let route = useRoute()
    let token = route.query.token

    onMounted(() => {
      http.get(`/users/confirmation?confirmation_token=${token}`)
      .then(() => {
        state.loading = false
        state.confirmed = true
      })
      .catch(() => {
        state.loading = false
        state.confirmed = false
      })
    })

    return {state}
  }
}


</script>


<style>

</style>