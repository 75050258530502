/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'mello_app' %> (and
// <%= stylesheet_pack_tag 'mello_app' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import "../util.css"
import 'marx-css/css/marx.css'
import '../base.css'

import * as Vue from 'vue'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import router from '../router'
import Home from '../Home.vue'
import Button from "../components/Button";
import Icon from '../components/Icon'

window.Vue = Vue

document.addEventListener('DOMContentLoaded', () => {

  let app = Vue.createApp(Home)
  app.component('Button', Button)
  app.component('Icon', Icon)
  app.use(router)
  app.use(Toast)
  app.mount('#app')

})

