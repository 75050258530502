<template>
  <div class="footer py-4">
    <ul class="content">
      <li class="my-2"><RouterLink class="text-lg font-bold" to="/">Blend Board</RouterLink></li>
<!--      <li class="my-2"><RouterLink class="text-lg" to="/faq">Full FAQs</RouterLink></li>-->
      <li class="my-2"><RouterLink class="text-lg" to="/blog/roadmap">Roadmap</RouterLink></li>
      <li class="my-2"><RouterLink class="text-lg" to="/blog/changelog">Changelog</RouterLink></li>
    </ul>
  </div>
</template>

<script>
import {reactive} from 'vue'
import BBLogo from '../stuff/BBLogo.svg'

export default {
  components: {
    BBLogo
  },
  props: {

  },
  setup: function (props) {

    let state = reactive({
      title: "Blend Board"
    })

    return {state}
  }
}
</script>

<style scoped>

.footer {
  height: 20rem;
  background-color: rgba(0,0,0, 0.7);
  color: white;
}

.footer a {
  color: white;
  text-decoration: none;
}

.content {
  max-width: 80%;
  width: 60rem;
  margin: auto;
  padding: 1rem 1.5rem;
}

</style>
