<template>
  <div class="bg-white shadow rounded">
    <slot></slot>
  </div>
</template>

<script>
import {reactive} from 'vue'

export default {
  components: {

  },
  setup: function (props) {

    let state = reactive({
      active: false
    })

    return {state}
  }
}
</script>

<style scoped>


</style>
