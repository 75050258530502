<template>
  <div style="height: 100%; max-width: calc(100vw - 15rem);" class="relative flex">
    <div class="_form " style="height: calc(100% - 4rem); width: 40rem; border-right: 2px solid #aaa;">
      <div class="absolute right-0 top-0 mx-4 my-2 cursor-pointer z-1">
        <Icon class="lg" name="close" @click="close()" />
      </div>

      <div class="relative h-full flex-grow">
        <div class="p-4 overflow-y-auto grid grid-cols-2 gap-x-4" style="max-height: calc(100% - 4rem);">

          <div class="col-span-2">
            <Label name="Name" :help="descriptions.name"/>
            <input type="text" v-model="state.selectedSource.name" />
          </div>

          <div class="col-span-2">
            <Label name="URL" :help="descriptions.url"/>
            <input type="text" v-model="state.selectedSource.url" />
          </div>

          <div class="col-span-2">
            <Label name="URL Parameters" :help="descriptions.urlParams"/>
            <table class="w-full break-all compact">
              <tr class="text-left">
                <th class="w-1/3">key</th>
                <th class="w-2/3">value</th>
              </tr>
              <tr v-for="param in state.selectedSource.urlParams">
                <td>
                  <ClickToEdit placeholder="new key" v-model="param.key" single-line />
                </td>
                <td>
                  <div class="flex flex-row justify-between items-center">
                    <ClickToEdit placeholder="new value" v-model="param.value" single-line />
                    <Button @click="deleteParam(param)" class="icon mx-2">
                      <Icon name="delete" />
                    </Button>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="2" class="text-center cursor-pointer" @click="addParam()">+ New Param</td>
              </tr>
            </table>
          </div>

          <div class="col-span-2">
            <Label name="Headers" :help="descriptions.headers"/>
            <table class="w-full break-all compact">
              <tr class="text-left">
                <th class="w-1/3">key</th>
                <th class="w-2/3">value</th>
              </tr>
              <tr v-for="header in state.selectedSource.headers">
                <td>
                  <ClickToEdit placeholder="new key" v-model="header.key" single-line />
                </td>
                <td>
                  <div class="flex flex-row justify-between items-center">
                    <ClickToEdit placeholder="new value" v-model="header.value" />
                    <Button @click="deleteHeader(header)" class="icon mx-2">
                      <Icon name="delete" />
                    </Button>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="2" class="text-center cursor-pointer" @click="addHeader()">+ New Header</td>
              </tr>
            </table>
          </div>


          <div class="col-span-1">
            <Label name="Reference ID" :help="descriptions.refId"/>
            <input type="text" v-model="state.selectedSource.cardMapping.refId" />
          </div>

          <div class="col-span-1">
            <Label name="Change Key" :help="descriptions.changeKey"/>
            <input type="text" v-model="state.selectedSource.cardMapping.changeKey" />
          </div>


          <div class="col-span-1">
            <Label name="Target List" :help="descriptions.list"/>
            <select v-model="state.selectedSource.panelId">
              <option v-for="panel in boardService.board.panels" :value="panel.id" :key="panel.id">{{panel.name}}</option>
            </select>
          </div>

          <div class="col-span-1">
            <Label name="Tags" :help="descriptions.tags"/>
            <div class="flex flex-row">
              <div style="padding: 10px 1rem" class="flex-grow rounded border border-gray-200">
                <CardTagList style="min-height: 1rem" :tags="state.selectedSource.tags" />
              </div>
              <PopperPro element-type="span" class="self-center">
                <Icon class="px-4 py-2 cursor-pointer" name="edit" />
                <TagSelection :taggable="state.selectedSource"/>
              </PopperPro>
            </div>
          </div>

          <div class="col-span-2">
            <Label name="Content Template" :help="descriptions.content"/>
            <input type="text" v-model="state.selectedSource.cardMapping.content" />
          </div>

        </div>

        <div class="flex flex-row w-full bottom-0 px-2 bg-white items-center rounded" style="height: 4rem">
          <Confirmable @click="deleteSource()">
            <Button class="outline-primary">Delete</Button>
          </Confirmable>
          <div class="flex-grow"></div>
          <Button class="outline-primary mx-1" @click="previewSource()">Preview</Button>
          <Button class="mx-1" @click="save()">Save</Button>
        </div>
      </div>
    </div>

    <div v-show="state.jsonResponse" class="overflow-auto" style="width: 40rem; height: calc(100% - 3rem)">
      <div class="child:inline-block child:p-2 p-4 child:mx-2 child:border child:rounded child:cursor-pointer">
        <div @click="state.previewType = PREVIEW.JSON"
            :class="{'active-border': state.previewType === PREVIEW.JSON, 'active-text': state.previewType === PREVIEW.JSON}">
          JSON
        </div>
        <div @click="state.previewType = PREVIEW.CARD"
            :class="{'active-border': state.previewType === PREVIEW.CARD, 'active-text': state.previewType === PREVIEW.CARD}">
          Cards
        </div>
      </div>
      <pre v-if="state.previewType === PREVIEW.JSON" style="white-space: pre" class="text-sm pb-8">{{state.jsonResponse}}</pre>
      <div v-if="state.previewType === PREVIEW.CARD" class="pb-8">
        <div class="bg-gray-200 p-2 rounded margin-auto" style="width: 20rem">
          <div v-for="card in state.cards" :key="card.uid"
              style="word-break: break-word;"
              class="w-full bg-white shadow p-2 my-2 rounded">
            <span v-html="enrich(card.content)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {reactive, watchEffect} from 'vue'
import _ from 'lodash'
import axios from 'axios'
import IndexArray from "index-array";
import boardService from "../services/board_service";
import ClickToEdit from "./ClickToEdit";
import {descriptions} from "./SourcePane.data.js";
import Icon from "./Icon";
import Label from "./form/Label";
import PopperPro from "./PopperPro";
import PopupMenu from "./popup/PopupMenu";
import HelpPopup from "./HelpPopup";
import CardTagList from "./CardTagList";
import TagSelection from "./TagSelection";
import Confirmable from "./popup/Confirmable";
import {nextId} from '../helpers/id_helper'
import {enrich} from '../helpers/rich_text_helper'
import {arrayToObject, objectToArray} from '../helpers/array_helper'

let newSource = defaultSource()

function defaultSource() {
  let result = {id: null, name: 'New Connection', url: '', urlParams: [], headers: [],
    cardMapping: {refId: '', content: ''}, tags: new IndexArray()}
  // let result = {id: null, name: 'Something'+nextId(), url: 'asdf', urlParams: [], headers: [],
  //   cardMapping: {refId: '$id', content: 'stuff'}, panelId: 20, tags: new IndexArray()}
  return result
}

const PREVIEW =
    {JSON: 'json', CARD: 'cards'}

export default {
  components: {
    Confirmable,
    TagSelection,
    CardTagList,
    HelpPopup,
    PopupMenu,
    PopperPro,
    Icon,
    Label,
    ClickToEdit,
  },
  emits: ['close'],
  props: {
    source: Object,
    controller: Object,
  },
  setup: function (props, context) {

    let state = reactive({
      selectedSource: newSource,
      jsonResponse: null,
      previewType: PREVIEW.JSON,
      cards: []
    })
    window.state = state

    watchEffect(() => {
      if (props.source?.id) {
        state.selectedSource = remapSource(props.source)
        state.jsonResponse = null
      } else {
        state.selectedSource = newSource
      }
    })

    watchEffect(() => {
      if (state.selectedSource?.url && state.previewType === 'cards') {
        state.cards = getCards(state.selectedSource, state.jsonResponse)
      }
    })

    function getCards(selectedSource, jsonResponse) {
      if (!Array.isArray(jsonResponse)) {
        return [{content: 'API response must be an array', tags: [], uid: nextId()}]
      }

      let newCards = jsonResponse.map((item) => {
        return {
          panelId: selectedSource.panelId,
          refId: interpolate(selectedSource.cardMapping.refId, item),
          content: interpolate(selectedSource.cardMapping.content, item),
          changeKey: interpolate(selectedSource.cardMapping.changeKey, item, true),
          changedAt: new Date().toISOString(),
          tags: selectedSource.tags.clone(),
          uid: nextId()
        }
      })

      return newCards
    }

    function interpolate(pattern, item, optional = false) {
      if (!pattern && optional) {
        return null
      }
      return pattern.replace(/\$([a-zA-Z0-9_.]+)/g, (match, key) => {
        // TODO: Throw error if key is missing. The user probably wants to know.
        return item[key]
      })
    }

    function close() {
      context.emit('close')
    }

    function save() {
      state.selectedSource.boardId = boardService.board.id
      boardService.saveSource(unmapSource(state.selectedSource))
          .then((savedSource) => {
            newSource = defaultSource()
            state.selectedSource = remapSource(savedSource)
          })
    }

    function deleteSource() {
      boardService.deleteSource(state.selectedSource)
          .then(() => {
            state.selectedSource = newSource
          })
    }

    function addParam() {
      state.selectedSource.urlParams.push({key: '', value: ''})
    }

    function deleteParam(param) {
      let index = state.selectedSource.urlParams.indexOf(param)
      state.selectedSource.urlParams.splice(index, 1)
    }

    function addHeader() {
      state.selectedSource.headers.push({key: '', value: ''})
    }

    function deleteHeader(header) {
      let index = state.selectedSource.headers.indexOf(header)
      state.selectedSource.headers.splice(index, 1)
    }


    function previewSource() {
      let source = unmapSource(state.selectedSource)
      axios.get(source.url, {params: source.urlParams || {}, headers: source.headers || {}})
      .then((response) => {
        state.jsonResponse = response.data
      }).catch((error) => {
        console.log({error})
        state.jsonResponse = error.response.data
      })
    }


    function remapSource(source) {
      return {...source,
        urlParams: objectToArray(source.urlParams),
        headers: objectToArray(source.headers),
      }
    }

    function unmapSource(source) {
      return {...source,
        urlParams: arrayToObject(source.urlParams),
        headers: arrayToObject(source.headers),
      }
    }


    return {state, addParam, deleteParam, addHeader, deleteHeader, enrich,
      save, boardService, deleteSource, descriptions, close, previewSource, PREVIEW}
  }
}
</script>

<style>
table.compact th {
  padding: 0.5rem;
}
table.compact td {
  padding: 0.5rem;
}
</style>
