<template>
  <span class="relative">
    <span :class="{'opacity-0': props.isLoading}"><slot></slot></span>
    <span :class="{'opacity-0': !props.isLoading, 'overlay': true}"><Loading/></span>
  </span>
</template>

<script>

import Loading from "./Loading";
export default {
  components: {Loading},
  props: {
    isLoading: {type: Boolean, default: false}
  },
  setup(props) {
    return {props}
  }
}

</script>

<style scoped>

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

</style>
