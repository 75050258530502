<template>
  <div>
    <Header>
      <RouterLink to="/login">
        <Button>Get Started</Button>
      </RouterLink>
    </Header>

    <div class="p-2 pt-20 pb-20 top-band text-center" style="background-color: rgb(255,255,255, 1)">
      <div class="px-2">
        <h1 class="font-bold" style="font-size: 32px">Connected and Simple.</h1>
        <h3 class="font-normal">Manage your personal kanban board</h3>
        <h3 class="font-normal">Sync up with REST APIs</h3>
        <RouterLink to="/login">
          <Button class="m-8">Get Started</Button>
        </RouterLink>
      </div>
      <img style="max-width: 90%; width: 60rem"
           class="margin-auto big-shadow"
           alt="Screenshot of a board in the application"
           :src="glossyPreview">
    </div>

    <div class="text-center py-12" style="background-color: rgb(255,255,255, 0.8)">

      <h2 class="m-0">FAQ</h2>
      <Accordion class="faq text-lg">
        <h4 class="m-4">Why was Blend Board created?</h4>
        <div class="text-left">
          <p>Like many projects, Blend Board was created out of frustration with existing tools. Platforms like GitLab give you a bare-bones to-do list that you can't reorganize or add notes to. Other to-do list apps are nice but don't integrated with external REST APIs or even allow whitespace and links in cards. Connecting them using an external automation platform was complicated and expensive. </p>
        </div>
      </Accordion>

      <Accordion class="faq text-lg">
        <h4 class="m-4">Who is this app for?</h4>
        <p>This app is designed for individuals to manage their work or their life, and who might want to synchronize with some external application that has a REST API. Similar to sticky notes on a board, you can organize cards into columns however you like, include links and add tags to categorize them. You can configure the REST API integration to create new cards for you or highlight existing cards when they've been updated.</p>
      </Accordion>

      <Accordion class="faq text-lg">
        <h4 class="m-4">Who is this app <span class="font-bold">not</span> for?</h4>
        <p>If you're looking for a project management tool or a collaboration tool with complex sharing and permissions, Blend Board isn't for you. If you're looking for a tool to schedule and automate workflows, this isn't for you either. Even our REST API integration must be called manually because we believe work should be pull-based, not push-based. </p>
      </Accordion>

      <Accordion class="faq text-lg">
        <h4 class="m-4">Why is Blend Board free?</h4>
        <p>We created Blend Board first and foremost because we wanted to improve our own lives. We've been using it almost every day since around April 2021 to manage the chaos that is GitLab and we want to share the joy. Server costs are still cheap but if that changes, we might ask for donations in the future. Time will tell.</p>
      </Accordion>

    </div>
    <Footer />

  </div>
</template>

<script>
import Header from './components/Header'
import {RouterLink} from 'vue-router'
import glossyPreview from '/images/glossy-preview2.png'
import Accordion from './components/Accordion'
import Footer from './components/Footer'


export default {
  components: {
    Header,
    RouterLink,
    Accordion,
    Footer
  },
  setup: function (props) {

    return {glossyPreview}
  }
}
</script>

<style scoped>
.big-shadow {
  box-shadow: 0 0 50px 0 rgb(0,0,0,0.1), 0 6px 18px 0px rgb(0,0,0,0.1)
}

.faq {
  max-width: 100%;
  width: 60rem;
  margin: 2rem auto;
  padding: 1rem 1.5rem;
}

.faq p {
  text-align: left;
}



</style>
