// import {reactive} from 'vue'
// import _ from 'lodash'

import http from './http_service'

let [SECOND, MINUTE] = [1000, 1000*60]

let subscriptions = {}
window.subscriptions = subscriptions

function Subscription(key) {
  let self = {
    _key: key,
    _timeout: null,
    _successCallbacks: [],
    _failureCallbacks: [],
    _focusListener: null,
    _lastUpdate: new Date(new Date() + 1*SECOND),
    onFailure,
    onSuccess,
    stop,
    upToDate,
  }

  function onSuccess(callback) {
    self._successCallbacks.push(callback)
    return self
  }

  function onFailure(callback) {
    self._failureCallbacks.push(callback)
    return self
  }

  function stop() {
    clearTimeout(self._timeout)
    window.removeEventListener('focus', self._focusListener)
    delete subscriptions[key]
  }

  function upToDate() {
    self._lastUpdate = new Date(new Date() + 1*SECOND)
  }

  return self
}

function subscribe(url, {params, delay} = {}) {
  delay = delay || 1*MINUTE
  let key = url // TODO: include params consistently somehow

  let subscription = subscriptions[key]
  if (!subscription) {
    subscription = new Subscription(key)
    subscriptions[key] = subscription

    let httpRequest = function() {
      return http.get(url, {params: {...params, since: subscription._lastUpdate}})
        .then((response) => {
          if (response.status === http.NOT_MODIFIED) {
            return
          }
          subscription._successCallbacks.forEach((callback) => {
            callback(response)
          })
          subscription.upToDate()
        })
        .catch((error) => {
          subscription._failureCallbacks.forEach((callback) => {
            callback(error)
          })
        })
    }

    let check = function() {
      let listener = (e) => {
        httpRequest().then(() => {
          subscription._timeout = setTimeout(check, delay)
        })
      }
      window.addEventListener('focus', listener, {once: true})
      subscription._focusListener = listener
    }

    subscription._timeout = setTimeout(check, delay)
  }

  return subscription
}


export {subscribe}
