<template>
  <Header />
  <h2 class="text-center">Clip</h2>

  <div v-if="state.token" style="max-width: 30em; width: 100%" class="margin-auto">
    <textarea v-model="state.content" @blur="onBlur" @change="state.changed=true"/>
    <div class="flex flex-row p-4">
      <Button class="outline-primary" @click="onOverwrite">Overwrite</Button>
      <div class="flex-grow"></div>
      <Button @click="onCopy()">Copy</Button>
    </div>
  </div>
  <div v-if="!state.token" class="text-center">
    <Button @click="onGenerate()">Generate Clip</Button>
  </div>

</template>

<script>
import {reactive, watch} from 'vue'
import {useRouter} from 'vue-router'
import http from "./services/http_service";
import Header from './components/Header'
import {subscribe} from './services/subscription_service'


export default {
  components: {
    Header,
  },
  props: {

  },
  setup: function (props) {
    let router = useRouter()
    let state = reactive({
      content: '',
      token: router.currentRoute.value.params.token || '',
      changed: false,
      subscription: null,
    })
    window.router = router
    window.state = state

    watch(() => state.token, () => {
      if (!state.token) return
      state.subscription?.stop()
      http.get(`/api/clips/${state.token}`)
      .then((response) => {
        state.content = response.data.content
        state.subscription = subscribe(`/api/clips/${state.token}`, {delay: 5000})
        .onSuccess((response) => {
          state.content = response.data.content
        })
      }).catch((error) => {
        console.log({error})
      })
    }, {immediate: true})

    function onGenerate() {
      http.put('/api/clips')
      .then((response) => {
        state.token = response.data.token
        state.content = response.data.content
        router.push(`/clips/${response.data.token}`)
      })
    }

    function onCopy() {
      navigator.clipboard.writeText(state.content)
    }

    function onOverwrite() {
      navigator.clipboard.readText()
      .then((text) => {
        state.content = text
        state.changed = true
        onBlur()
      })
    }

    function onBlur() {
      if (!state.changed) return
      state.changed = false

      http.put('/api/clips', {
        token: state.token,
        content: state.content
      }).then((response) => {
        console.log({response})
      })
    }

    return {props, state, onBlur, onCopy, onOverwrite, onGenerate}
  }
}
</script>

<style scoped>

</style>
