import axios from 'axios'
import {useToast} from 'vue-toastification'
import _ from 'lodash'
import {decorateFull} from '../helpers/array_helper'

let toast = useToast()

let HttpService = {
  delete: wrap(axios.delete),
  get:    wrap(axios.get),
  post:   wrap(axios.post),
  put:    wrap(axios.put),
  patch:  wrap(axios.patch),
  NOT_MODIFIED: 304,
}

function wrap(method) {
  return (...args) => {
    return method(...args)
      .then((response) => {
        response.rawData = _.cloneDeep(response.data)
        response.data = decorateFull(response.data)
        return response
      })
      .catch((error) => {
        if (error.response.status === HttpService.NOT_MODIFIED && error.response.config.params?.since) {
          return error.response
        }
        if (error.response.status === 401) {
          toast.error('You are not currently logged in.')
          throw error
        }
        toast.error('Something went wrong.')
        throw error
      })
  }
}

window.http = HttpService

export default HttpService