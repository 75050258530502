<template>
  <div class="side-nav bg-white shadow flex"
       :class="{inactive: !props.active}">
    <div class="menu-pane inline-block bg-white shadow"
      style="width: 15rem;">
      <ul class="p-4 child:py-2">
        <li class="flex flex-row items-center">Connections <div class="line"/></li>
        <li v-for="source in state.board.sources"
            class="pl-2 cursor-pointer hover:bg-gray-100"
            :class="{'active-text': state.selectedSource === source}"
            @click="selectSource(source)">
          {{source.name}}
        </li>
        <li class="cursor-pointer hover:bg-gray-100"
            @click="createSource()">+ New Connection </li>
        <!-- <li class="flex flex-row items-center">Boards <div class="line"/></li> -->
      </ul>
    </div>
    <div class="inline-block" v-if="state.selectedSource">
      <SourcePane @close="closeSource()" :source="state.selectedSource"/>
    </div>
  </div>
</template>

<script>
import {reactive, computed, watchEffect} from 'vue'
import boardService from '../services/board_service'
import SourcePane from './SourcePane'
import IndexArray from 'index-array'

function defaultSource(uid = null) {
  let result = {id: null, name: 'New Connection', url: '', urlParams: [], cardMapping: {refId: '', content: ''}, tags: new IndexArray()}
  if (uid) result.uid = uid
  return result
}

export default {
  components: {
    SourcePane
  },
  props: {
    active: {type: Boolean, default: false}
  },
  setup: function (props) {

    let state = reactive({
      board: boardService.board,
      selectedSource: null,
      sourceController: {}
    })


    function selectSource(source) {
      state.selectedSource = source
    }

    function createSource() {
      state.selectedSource = {}
    }

    function closeSource() {
      state.selectedSource = null
    }

    return {state, props, selectSource, createSource, closeSource}
  }
}
</script>

<style scoped>

.side-nav {
  height: 100%;
  z-index: 10;
  left: 0;
  position: fixed;
  transition: width 500ms;
}

.side-nav.inactive {
  width: 0rem;
  overflow-x: hidden;
}

.menu-pane {
  border-right: 2px solid #aaa;
}

.line {
  flex-grow: 1;
  height: 0;
  border-bottom: solid #aaa 2px;
  display: inline-block;
  margin-left: 0.5rem;
  margin-top: 0.25rem;
}


</style>
