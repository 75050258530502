<template>
  <div style="height: 100vh;"  class="flex flex-col justify-center">

    <div style="max-width: 30em; width: 100%" class="login-box bg-white shadow self-center p-4 rounded">
      <h3 class="mt-0">Reset Password</h3>
      <form class="my-4">
        <label class="font-bold">New Password</label>
        <input type="password" v-model="state.password" />
        <label class="font-bold">Confirm Password</label>
        <input type="password" v-model="state.confirmPassword" @keydown.enter="onSubmit()" />
      </form>

      <div class="flex flex-row">
        <Button class="outline-primary" @click="startRegister(true)">Create Account</Button>
        <div class="flex-grow"></div>
        <Button @click="onSubmit()">Reset</Button>
      </div>
    </div>

  </div>

</template>

<script>
import {reactive} from 'vue'
import {useRoute, useRouter} from "vue-router";
import {passwordRegex} from "./helpers/regexes";
import {useToast} from 'vue-toastification'
import http from './services/http_service'
import {fastHash} from './helpers/encryption_helper'

let toast = useToast()

export default {
  setup() {
    let state = reactive({
      password: '',
      confirmPassword: '',
    })
    let route = useRoute()
    let router = useRouter()
    let token = route.query.token

    function validate() {
      let isPasswordValid = !!state.password.match(passwordRegex)
      let passwordsMatch = state.password === state.confirmPassword
      if (!isPasswordValid) {
        toast.error('Password must be at least 12 characters long.')
        return false
      }
      if (!passwordsMatch) {
        toast.error("Passwords don't match.")
        return false
      }
      return true
    }

    function onSubmit() {
      if (!validate()) return

      http.put('/users/password', {
        user: {
          password: fastHash(state.password),
          password_confirmation: fastHash(state.confirmPassword),
          reset_password_token: token
        }
      })
      .then((response) => {
        toast.success('Password was successfully reset!')
        router.push('/login')
      })
    }

    return {state, onSubmit}
  }
}


</script>


<style>

</style>