import CryptoJS from "crypto-js"

window.crypt = {encrypt, decrypt, slowHash, fastHash}

// Use for encrypting url params. Password should be slow hashed password+hash_key
function encrypt(message, password) {
  return CryptoJS.AES.encrypt(message, password).toString();
}

// Use for decrypting url params
function decrypt(ciphertext, password) {
  // TODO: throw error if password is wrong
  let bytes  = CryptoJS.AES.decrypt(ciphertext, password);
  let message = bytes.toString(CryptoJS.enc.Utf8);
  return message
}

// Use for saving passwords locally in combination with private hash_key/salt from user
function slowHash(password, hash_key, work = 12) {
  work = Math.max(work, 8)
  return CryptoJS.PBKDF2(password, hash_key, {iterations: 2**work, keySize: 256/32}).toString()
}

// Use before sending password to server on login
function fastHash(password) {
  return CryptoJS.SHA256(password).toString();
}

export {encrypt, decrypt, slowHash, fastHash}