<template>
  <div class="relative">
    <Header>
      <template #left>
        <Button class="menu clear" @click="toggleNav()">
          <Icon class="lg" name="menu" />
        </Button>
      </template>

      <div class="flex flex-row items-center">
        <Button class="mx-1" @click="sync()">
          <LoadingOverlay :is-loading="state.syncLoading">
            <Icon name="sync" /> Sync
          </LoadingOverlay>
        </Button>

        <Button class="mx-1" @click="logOut()">
          <Icon name="log-out" /> Log out
        </Button>
      </div>
    </Header>

    <SideNav :active="state.activeNav"/>

    <IndexDraggable item-key="uid"
                    class="panel-list"
                    :delay="state.draggableDelay"
                    v-model="state.board.panels"
                    @change="onDragPanel($event)">
      <template #item="{element: panel}">
        <Panel :key="panel.uid" :panel="panel">
        </Panel>
      </template>
      <template #footer>
        <Button class="m-2" @click="createPanel()">
          <Icon name="addCircle" /> New List
        </Button>
      </template>
    </IndexDraggable>

  </div>
</template>

<script>
import {reactive, watchEffect, onUnmounted} from 'vue'
import boardService from './services/board_service'
import {nextId} from "./helpers/id_helper";
import IndexArray from "index-array";
import useLogin from './services/login_service'

import Header from "./components/Header";
import IndexDraggable from "./components/IndexDraggable";
import Loading from './components/loading/Loading'
import LoadingOverlay from "./components/loading/LoadingOverlay";
import Panel from "./components/Panel"
import SideNav from './components/SideNav'


export default {
  components: {
    Header,
    IndexDraggable,
    Loading,
    LoadingOverlay,
    Panel,
    SideNav,
  },
  setup: function () {


    let state = reactive({
      board:{
        id: nextId(),
        panels:[]
      },
      syncLoading: false,
      activeNav: false,
    })

    let {logOut, loginState} = useLogin()

    watchEffect(() => {
      if (loginState.currentUser) {
        boardService.load(1)
        .then(() => {
          state.board = boardService.board
        })
      }
    })

    onUnmounted(() => {
      boardService.unsubscribe()
    })


    function createPanel() {
      boardService.addPanel({uid: nextId(), name: 'New List', cards: new IndexArray()})
    }


    function sync() {
      state.syncLoading = true
      boardService.syncSource()
      .finally(() => {
        state.syncLoading = false
      })
    }

    function deletePanel(panel) {
      boardService.deletePanel(panel)
    }


    function onDragPanel(e) {
      boardService.updateBoard(state.board)
    }

    function toggleNav() {
      state.activeNav = !state.activeNav
    }

    return {state, createPanel, onDragPanel,
      deletePanel, sync, logOut, toggleNav}
  }
}
</script>

<style scoped>

.panel-list {
  position: relative;
  height: calc(100vh - 5.5rem);
  overflow-y: hidden;
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
}

button.menu {
  padding: 0.5rem;
  color: #444;
}



</style>
