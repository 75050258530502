
let descriptions = {
  name: `This is whatever you would like to call your source.`,
  url: `This is the url of the REST API you would like to synchronize with. Ex: https://gitlab.com/api/issues`,
  refId: `This is the unique identifier of a generated card. It's what prevents new tickets from being created the second time the same data is returned by your API. You can refer to fields in the returned data with the $ prefix. For example, "Issue $id" might become "Issue 123".`,
  content: `This is the template that is used to convert data from your API to the content of a card. You can refer to fields in the returned data with the $ prefix. For example, "Issue #$id $title: $web_url" might become "Issue #123 The bug is still happening: http://gitlab.com/issues/123". (URLs are clickable in cards)`,
  list: `Which list the card will be added to.`,
  changeKey: `This is the data we look at to determine whether to show you that something has changed for a card. For example, if you use "$updated_at", the card will indicate that something has changed every time the "updated_at" field changes in your API. `,
  urlParams: `You can specify URL parameters that you would like to append to the URL. You could just as well put them directly in the URL field if you prefer. It's up to you!`,
  headers: `You can specify headers that you would like to include in API requests. Some REST APIs require specific headers for authentication.`,
  tags: `These tags will appear on new cards.`,
}


export {descriptions}