<template>
  <div class="opaque-background">
    <div class="nav-backdrop"></div>
    <div class="mystery-placeholder"></div>
  </div>
  <div class="flex top-nav items-center justify-between px-2">
    <div class="flex flex-row items-center">
      <slot name="left"></slot>
      <BBLogo height="2rem" width="11.5rem"/>
    </div>
    <slot></slot>
  </div>


  <div class="nav-spacer"></div>
</template>

<script>
import {reactive} from 'vue'
import BBLogo from '../stuff/BBLogo.svg'

export default {
  components: {
    BBLogo
  },
  props: {

  },
  setup: function (props) {

    let state = reactive({
      title: "Blend Board"
    })

    return {state}
  }
}
</script>

<style scoped>

.top-nav {
  height: 4rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  overflow: hidden;
}
.nav-spacer {
  height: 4rem;
  position: relative;
  overflow: hidden;
}

.opaque-background {
  position: fixed;
  height: 4rem;
  overflow: hidden;
  z-index: 1;
}

.nav-backdrop {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(120deg, var(--background-color-1) 0%, var(--background-color-2) 100%);
}

/* No idea why this is needed */
.mystery-placeholder {
  width: 100vw;
  height: 0;
}

</style>
