<template>
  <Header />
  <div style="height: 80vh;"  class="flex flex-col justify-center">
    <div style="max-width: 30em; width: 100%" class="login-box bg-white shadow self-center p-4 rounded">
      <h3 class="mt-0 text-center">Log in</h3>
      <form class="my-4">
        <label class="font-bold">Email</label>
        <input type="text" v-model="state.email"/>
        <label class="font-bold">Password</label>
        <input type="password" v-model="state.password" @keydown.enter="onLogin()" />
        <div v-if="state.registering">
          <label class="font-bold">Confirm Password</label>
          <input type="password" v-model="state.confirmPassword" @keydown.enter="onRegister()" />
        </div>
      </form>
      <div class="my-4">
        <a class="cursor-pointer" @click="sendPasswordReset">Forgot password?</a>
      </div>

      <div v-if="!state.registering" class="flex flex-row">
        <Button class="outline-primary" @click="startRegister(true)">Create Account</Button>
        <div class="flex-grow"></div>
        <Button @click="onLogin()">Log in</Button>
      </div>

      <div v-if="state.registering" class="flex flex-row">
        <Button class="outline-primary" @click="startRegister(false)">Login Instead</Button>
        <div class="flex-grow"></div>
        <Button @click="onRegister()">Create Account</Button>
      </div>
    </div>

  </div>
</template>

<script>
import {reactive, watchEffect} from 'vue'
import useLogin from "./services/login_service";
import {emailRegex, passwordRegex} from "./helpers/regexes";
import {useRouter} from 'vue-router'
import {useToast} from 'vue-toastification'
import http from "./services/http_service";
import Header from './components/Header'

let toast = useToast()

export default {
  components: {
    Header,
  },
  props: {

  },
  setup: function (props) {
    let state = reactive({
      email: '',
      password: '',
      confirmPassword: '',
      registering: false,
    })
    let {logIn, register, loginState} = useLogin()
    let router = useRouter()

    watchEffect(() => {
      if (loginState.currentUser) {
        router.push('/boards')
      }
    })

    function onLogin() {
      if (!validate()) return

      return logIn(state.email, state.password)
    }

    function validate() {
      let isEmailValid = !!state.email.match(emailRegex)
      let isPasswordValid = !!state.password.match(passwordRegex)
      if (!isEmailValid) {
        toast.error('Email is invalid.')
        return false
      }
      if (!isPasswordValid) {
        toast.error('Password must be at least 12 characters long.')
        return false
      }
      return true
    }

    function startRegister(registering) {
      state.registering = registering
      if (!registering) {
        state.confirmPassword = ''
      }
    }

    function onRegister() {
      if (!validate()) return
      if (state.password !== state.confirmPassword) {
        toast.error("Passwords don't match.")
        return
      }

      return register(state.email, state.password)
    }

    function sendPasswordReset() {
      let isEmailValid = !!state.email.match(emailRegex)
      if (!isEmailValid) {
        toast.error('Email is invalid')
        return
      }
      http.post('/users/password', {user: {email: state.email}})
      .then(() => {
        toast.success("Password reset sent!")
      })
    }


    return {props, state, onLogin, startRegister, onRegister, sendPasswordReset}
  }
}
</script>

<style scoped>

</style>
