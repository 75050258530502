<template>
  <div class="flex flex-row">
    <div style="min-width: 2rem"  v-for="tag in rootTags" :style="tagColorStyle(tag)" class="h-4 rounded px-2 text-xs" :key="tag.uid">
      {{tag.name}}
    </div>
  </div>
</template>

<script>

import {computed} from 'vue'
import boardService from "../services/board_service";
import {hexToRgb} from "../helpers/color_helper";

export default {
  props: {
    tags: Array
  },
  setup(props) {


    let rootTags = computed(() => {
      if (!props.tags?.toArray) return []
      return props.tags.toArray().map((tag) => {
        return boardService.board.tags.fetch({id: tag.id})
      }).filter((tag) => !!tag)
    })

    function tagColorStyle(tag) {
      let rgb = hexToRgb(tag.color)
      let grayLevel = 0.2126*rgb.r + 0.7152*rgb.g + 0.0722*rgb.b
      let textColor
      if (grayLevel > 180) {
        textColor = '#000'
      } else {
        textColor = '#fff'
      }

      return {
        'background-color': tag.color,
        color: textColor
      }
    }

    return {props, rootTags, tagColorStyle}
  }
}





</script>