<template>
  <div v-if="requiresAuth && !loginState.loginComplete">Loading...</div>
  <router-view v-else/>
</template>

<script>
import {watchEffect, computed} from "vue";
import {useRouter, useRoute} from 'vue-router'
import useLogin from './services/login_service'

export default {
  components: {

  },
  props: {

  },
  setup: function (props) {

    let router = useRouter()
    let route = useRoute()
    let {loginState, checkLogin} = useLogin(false)
    let requiresAuth = computed(() => {
      return !!route.meta.auth
    })

    watchEffect(() => {
      if (!requiresAuth.value) {
        return
      }
      checkLogin()
      if (loginState.loginComplete && !loginState.currentUser) {
        router.push('/login')
      }
    })


    return {props, loginState, requiresAuth}
  }
}
</script>

<style scoped>


</style>
