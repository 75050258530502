<template>
  <div class="bg-white shadow flex flex-col" >
    <div class="py-1 px-4 flex flex-row items-center" v-for="tag in board.tags" :key="tag.uid">
      <input @change="toggleActive(tag)" :checked="isActive(tag)" class="m-0 self-center" type="checkbox" />
      <input  style="padding: 0; border: none; height: 2rem; width: 3rem;" class="m-0 h-2 w-3"
          @change="colorChange(tag)"
          v-model="tag.color" type="color" />
      <div class="w-40">
        <ClickToEdit @endEdit="onEditTagName(tag)"
            placeholder="Unnamed"
            v-model="tag.name" single-line />
      </div>
      <Button @click="deleteTag(tag)" class="icon m-2">
        <Icon name="delete" />
      </Button>
    </div>
    <PopupItem @click="createTag()" class="self-center">
      New Tag
    </PopupItem>
  </div>
</template>

<script>
import {reactive} from 'vue'
import boardService from '../services/board_service'
import _ from 'lodash'
import ClickToEdit from "./ClickToEdit";
import PopupItem from "./popup/PopupItem";

export default {
  components: {
    PopupItem,
    ClickToEdit,
  },
  emits: ['select'],
  props: {
    taggable: Object,
  },
  setup: function ({taggable}, context) {

    let board = boardService.board
    let state = reactive({
      newColor: '',
      colorPicker: {}
    })

    function isActive(tag) {
      let index = _.findIndex(taggable.tags, {id: tag.id})
      return index > -1
    }

    function toggleActive(tag) {
      if (!isActive(tag)) {
        taggable.tags.push(tag)
      } else {
        let index = _.findIndex(taggable.tags, {id: tag.id})
        if (index > -1) {
          taggable.tags.splice(index, 1)
        }
      }
      context.emit('select')
    }


    function createTag() {
      boardService.createTag({name: null, color: '#000000'})
    }

    function deleteTag(tag) {
      boardService.deleteTag(tag)
    }

    function colorChange(tag) {
      boardService.updateTag(tag)
    }

    function onEditTagName(tag) {
      boardService.updateTag(tag)
    }

    return {state, taggable, board, toggleActive, createTag, deleteTag, colorChange, onEditTagName, isActive}
  }
}
</script>

<style>


</style>
