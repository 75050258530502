import * as VueRouter from 'vue-router'
import Blog from './Blog'
import Board from './Board'
import Clip from './Clip'
import Landing from './Landing'
import Login from './Login'
import PasswordReset from './PasswordReset'
import EmailConfirmation from './EmailConfirmation'

let router = VueRouter.createRouter({
  history:VueRouter.createWebHistory(),
  routes: [
    {path: '/', component: Landing, meta: {auth: false}},
    {path: '/boards', component: Board, meta: {auth: true}},
    {path: '/login', component: Login, meta: {auth: true}},
    {path: '/password_reset', component: PasswordReset, meta: {auth: false}},
    {path: '/email_confirmation', component: EmailConfirmation, meta: {auth: false}},
    {path: '/clips/:token?', component: Clip, meta: {auth: false}},
    {path: '/blog/:slug?', component: Blog, meta: {auth: false}},
    {path: '/:other(.*)*', redirect: "/boards"},
  ],
  scrollBehavior(to, from, savedPosition) {
    return {top: 0}
  }
})

export default router